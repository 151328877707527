import {
    collection,
    CollectionReference,
    doc, getDoc,
    getDocs, orderBy,
    query,
    QuerySnapshot, setDoc,
    Timestamp,
    updateDoc, where
} from "firebase/firestore";
import FirebaseService from "./firebaseService";
import {IUserVerification} from "../types/UserVerification";
import {Collection} from "../constants/Collection";
import {sendUserNotification} from "../utils/notificationUtils";
import {NotificationType} from "../constants/NotificationType";


class UserVerificationService {
    ref: CollectionReference;

    constructor() {
        const db = FirebaseService.db;
        this.ref = collection(db, 'UserVerification');
    }

    async getUnreviewedRequests(): Promise<IUserVerification[]> {
        const res = await getDocs(
            query(this.ref,
                where('isInReview', '==', true),
                orderBy('submittedOn', 'asc')
            )
        );

        return this._toUserVerifications(res);
    }

    async getUserVerification(userVerificationId: string): Promise<IUserVerification | undefined> {
        const res = await getDoc(doc(this.ref, userVerificationId));

        if(res.exists()) {
            return {...res.data(), id: res.id} as IUserVerification;
        }

        return undefined;
    }

    async denyUserVerification(userId: string): Promise<void> {
        await this._updateUserVerification(userId, {
            isInReview: false,
            isVerified: false
        });

        await sendUserNotification(userId, {
            title: 'Your profile verification has been denied',
            message: 'Please update your profile photos and resubmit',
            data: {
                type: NotificationType.PROFILE_VERIFICATION_DENIED
            }
        });
    }

    async toggleUserVerification(userId: string, isVerified: boolean): Promise<Partial<IUserVerification>> {

        const data: Partial<IUserVerification> = {
            isVerified,
            isInReview: false,
            verifiedOn: isVerified ? Timestamp.now() : null
        }

        if(isVerified) {
            await sendUserNotification(userId, {
                title: 'Your profile has been verified!',
                message: '',
                data: {
                    type: NotificationType.PROFILE_VERIFIED
                }
            });
        }


        await this._updateUserVerification(userId, data);

        return data;
    }

    private _toUserVerifications(snapshots: QuerySnapshot): IUserVerification[] {
        return snapshots.docs.filter((doc) => doc.exists()).map((doc) => {
            return {...doc.data(), id: doc.id} as IUserVerification;
        });
    }

    private async _updateUserVerification(userVerificationId: string, payload: Partial<IUserVerification>): Promise<void> {
        const ref = doc(FirebaseService.db, Collection.USER_VERIFICATION, userVerificationId);
        await setDoc(ref, payload, { merge: true});
    }
}

export default new UserVerificationService();